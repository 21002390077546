/* .img_container{
    background-color:#F0F8FF;
    padding: 50;
}
 */

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height:550px !important;
   
}
.image-gallery-slide .image-gallery-image {
    width: 100% !important;
   object-fit: cover !important;
}

