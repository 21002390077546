@import url('https://fonts.googleapis.com/css?family=Open Sans');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
  font-family: "Open Sans Sans-Serif" !important;
  font-style: normal;
  font-weight: 300;
}

p {
  font-family: "Open Sans Sans-Serif" !important;
  letter-spacing: 1px;
  line-height: 1.8;
  font-size: 14px;
}

.row {
 /*  --bs-gutter-x: 0; */
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x)*0.5);
  padding-left: calc(var(--bs-gutter-x)*0.5);
}

.col_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --bg-color-846: #8463F4;
  --bg-color-e74: #4a82cc;
  --text-color-cc8: hsl(194, 94%, 50%);
  --text-color-fff: #FFFFFF;
  --text-purple-color-952: #952DBA;
  --text-gold-color-d4a: #6cce63;
  --text-black-color-000: #161129;
  --text-color-404: #404026;
  --text-orange-color-455: hsl(41, 94%, 50%);
  --text-gray-color-447: hsl(180, 100%, 99%);
  --text-color-0e3: #0e3100;
  --scrollbarBG: #101314;
  --thumbBG: #90A4AE;
}

/* width */
/* ::-webkit-scrollbar {
  width: 11px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: var(--scrollbarBG); */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */