.infertility{
    text-align: center;
    height: 500px;
}
.infertility_img{
    height: 400px;
    width: 900px;
}
.infer_text{
    font-weight:500;
    color: blue;
    font-size: 30px;
  
}
