.bread_row_bg{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
 /*    margin-top: 90px; */
    height: 70vh;
    /* background-image: url("/src/Utils/Images/latest/Promo4.jpeg"); */
    background-size: cover;
    background-position: center;
}
.breadcrumb-list{
    opacity: none;
    color: #fff !important;
    font-weight: 600;
    text-decoration: none;
}
.breadcrumb-divider{
    position: relative;
    font-size: 20px;
    font-weight: 600 !important;
    color: #fff;
    padding: 10px;
    font-weight: 800;
}