/* Home Facilities */

.homefacilities-container{
    position: relative;
    padding: 30px 0px 30px;
    /* background-color: #dad2d2; */
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center !important;
  font-size: 18px;
  background: linear-gradient( 
    0deg, rgb(10 67 76) 0%, rgb(30 97 108) 48%);


  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inline-table;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.homefacility-slider{
  display:flex;
  flex-direction: column-reverse;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.slide-img {
  display: flexbox;
  width: 60%;
}

.homefacility-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 50vh !important;
  padding: 50px 40px 55px;
  text-align: center;
}

.homefacility-main-title{
  position: relative;
  padding: 20px 0px 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 22px;
  color:var(--bg-color-e74);
  text-align: center;
}

.homefacility-title{
  position: relative;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color:white;
}

.homefacility-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: white;
}

.homefacility-link{
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 42px;
    padding: 0 20px;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 10px;
    outline: none;
    background-color:var(--text-gold-color-d4a);
}

