/* Home Contact */

.home-contact-container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;
  background-image: url("../../Images/map-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.home-contact-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--text-color-cc8);
}

.home-contact-title-one {
  position: relative;
  padding: 20px 0px 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 42px;
  color: var(--bg-color-e74);
  text-align: start;
}

.home-contact-title-one {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.home-contact-list li {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0;
  margin-top: 20px;
  list-style: none;
  margin-bottom: 30px;
}

.home-contact-list a {
  letter-spacing: 1.5px;
}

.home-contact-img {
  display: inline;
  width: 40px;
  margin-right: 10px;
}

.home-contact-form-col {
  position: relative;
  background: #ffffff;
  overflow: hidden;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 43px 0px rgb(131 131 131 / 23%);
}

.contact-form {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.address {
  margin-left: 40px;
  font-size: 13px;
}

/* Contact One */

.add_card_title {
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: var(--bg-color-e74) !important;
  text-transform: uppercase !important;
}

.add_card_title_one {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
}

.add_card_text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 1px !important;
}