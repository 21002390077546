
.home_ourlocation-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}
.home-location-title{
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-black-color-000);
}
.home-location-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-black-color-000) !important;
}
.home-location-col{
    background-color: rgb(238, 238, 238) !important;
    cursor: pointer;
    /* box-shadow: 0 0 40px rgba(207, 206, 206, 0.938); */
    box-sizing: border-box;
}