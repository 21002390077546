.beautycare img {
  width: 475px;
  height: 300px;
  display: block;
box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%);
}
.beautyheading{
  color: var(--bg-color-e74);
  font-weight: 600;
  font-size: 30px;
}
.result{
  font-size:50px;
  color:green;
  margin-right:15px;
  }
  /*   p {
      font-size: 15px;
    } */
  .media p {
      font-size: 18px;
      padding-top: 10px;
        color: green;
  }
  .media {
    
    display: flex;
    
}