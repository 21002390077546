.progress-container{
    position: relative;
    padding: 30px 0px 30px;
 
 }
 .progress-title{
     position: relative;
    /*  font-style: normal; */
     font-weight: 600;
     font-size: 20px;
     line-height: 28px;
     color:var(--text-black-color-000);
 }
 
 .progress-title-one{
     font-style: normal;
     font-weight: 900;
     font-size: 22px;
     line-height: 22px;
     color:var(--bg-color-e74);
     text-align: center;
 }
 
 .progress-time{
    /*  font-style: normal; */
     font-weight: normal;
     font-size: 14px;
     font-weight: 600;
     line-height: 20px;
     color:var(--bg-color-e74);
     text-align: center;
 }
 .prog-logo{
     position: relative;
     width: 100%;
 }
 
 @media (max-width: 500px) {
    .progress-title-one{
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 22px;
        color:var(--bg-color-e74);
        text-align: center;
    }
     .progress-time{
        /*  font-style: normal; */
        font-size: 18px; 
        font-weight: 500;
        line-height: 20px;
        color:var(--bg-color-e74);
        text-align: center;
    }
 }