
    .services-head {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 10px 20px;
        background:#1e6544;
        
        
    }
    .services-title{
        color: white;
        font-size: 17px;
        text-align: center;
    }
    .service-card{
    margin-bottom:30px;
    }
    
    .service-card .card ul{
    padding-left:0px;
    margin-left:20px; 
      
    }
    .service-card .card ul li{
        text-align: left;
        font-family:'Poppins';
        letter-spacing: 1px;
        line-height: 1.8;
        font-size: 13px; 
        padding-bottom: 10px;

         
        }
  
    .services-body{
        min-height: 330px;
        margin-top: 15px;
    }