.franchise_title {
    /* font-style: normal; */
    font-family:'Poppins';
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    /* color:var(--bg-color-e74); */
    text-align: center;
}

.franchise_title_one {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* color:var(--bg-color-e74); */
    text-align: center;
}

.franchise_title_two {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--bg-color-e74);
    text-align: center;
}

.franch_row {
    background-image: url("../../Images/franch.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.summer_camp_list {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--text-color-fff);
    background-color: #696969;
    padding: 10px;
    margin-top: 5px;
    width: 50%;
    padding: 20px;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 1px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.summer_camp_list span {
  /*   font-style: normal; */
  font-family:'Poppins';
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--text-color-fff);
}

@media (min-width: 360px) and (max-width: 720px){
    .summer_camp_list {
        width: 100%;
    }
}
