.register_col_form{
    position: relative;
    background: #ffffff;
    overflow: hidden;
    padding: 40px 60px 60px !important;
    border-radius: 15px;
    box-shadow: 0px 0px 43px 0px rgb(131 131 131 / 23%);
    margin-left: -10px;
  }
  .register_col_form_one{
      position: relative;
      background: #ffffff;
      overflow: hidden;
      padding: 40px 60px 60px !important;
      border-radius: 15px;
      box-shadow: 0px 0px 43px 0px rgb(131 131 131 / 23%);
      margin-left: 10px;
    }
  
  .register_col_form input{
      border-radius: 15px !important;
      line-height: 2;
      font-size: 14px;
      font-family: 'Poppins';
  }
  .register_col_form select{
      border-radius: 15px !important;
  }
  .register_col_form label{
      font-size: 15px;
      font-family: 'Poppins';
      letter-spacing: 0.5px !important;
      font-weight: 500;
  }
  .register_form_col_btn{
      position: relative;
      margin-top: 40px !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
      
  }
  .register_form_btn{
      padding: 10px 15px 10px;
      background-color: var(--bg-color-e74);
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color-fff) !important;
      text-align: center;
      text-decoration: none;
      border: 1px solid var(--text-color-fff);
      box-shadow: 0px 4px 4px rgb(228, 202, 240);
      border-radius: 10px;
      /* margin-top: 20px; */
      margin-left: 0px !important;
      margin-right: 0px !important;
  }
  .register_col_form_one h3{
      font-size: 16px;
      letter-spacing: 0.5px !important;
      font-weight: 500;
      color: var(--text-color-222);
      margin-bottom: 10px;
      text-align: center;
  }
  .register_col_form_one h4{
      font-size: 14px;
      font-family: 'Poppins';
      letter-spacing: 0.5px !important;
      font-weight: 500;
      color: var(--text-color-000);
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      line-height: 1.5;
  }
  
  /* Success Page */
  
  .success_page{
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      /* flex-direction: row; */
      justify-content: center;
      align-items: center;
      text-align: center;
  }
  .lottie_img{
      position: relative;
      width: 20% !important;
  }
  .success_link{
      padding: 10px 20px 10px;
      background-color: var(--bg-color-e74);
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color-fff) !important;
      text-align: center;
      text-decoration: none;
      border: 1px solid var(--text-color-fff);
      box-shadow: 0px 4px 4px rgb(228, 202, 240);
      border-radius: 10px;
      margin-top: 20px !important;
     
  }
  .success_text{
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      color: var(--bg-color-e74);
      
  }
  .hecolor{
    color: var(--bg-color-e74) !important;
  }