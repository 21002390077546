.home-product-container{
    position: relative;
    padding: 30px 0px 30px;
    background-color: #dee2e6;
}

.home-product-main-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}

.home-product-image:hover{
    cursor: pointer;
    border-color:  var(--text-gold-color-d4a);
    box-shadow: 0 0 40px rgba(147, 168, 150, 0.938);
    transform: translateY(-5px);
    z-index: 1;
}

.home-product-title{
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color:var(--text-black-color-000);
}

.home-product-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-black-color-000) !important;
}