
.expo_img{
    position: relative;
    display: block;
    width: 100%;
} 
.expo_one_title{
 /*    font-style: normal; */
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color:var(--bg-color-e74);
}
.expo_col{
/*     font-style: normal; */
    font-weight: 400;
    /* font-size: 14px;
    line-height: 22px; */
    color:var(--text-black-color-000);
    text-align: justify;
}
.expo_one_row_bg{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    /* text-align: center; */
}
.expo_one_btn{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px !important;
    color: var(--text-color-fff) !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    height: 42px !important;
    padding: 0 40px !important;
    cursor: pointer !important;
    white-space: nowrap !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    outline: none !important;
    background-color:var(--text-gold-color-d4a) !important;
}

/* YogaOne Camp */

.yoga-camp_swiper{
    width: 100%;
    height: 100%;
}
.yoga_camp_slide{
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.yoga_camp_slide img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.yoga_camp_p{
    /* font-style: normal; */
    font-weight: 600;
    font-size: 46px;
    /* line-height: 22px; */
    color:var(--text-black-color-000);
}
.yoga_camp_btn{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px !important;
    color: var(--text-color-fff) !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    height: 42px !important;
    padding: 0 40px !important;

    cursor: pointer !important;
    white-space: nowrap !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    outline: none !important;
    background-color:var(--text-gold-color-d4a) !important;
}
.yoga_img{
    background-color: #7F3F98;
    opacity: 0.5;
}
.yoga_title{
    text-align: center;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 500;
}
.yoga_two_title{
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 22px;
  color:var(--bg-color-e74);
  text-align: center;
}
.yoga_btn{
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
}
.yoga_camp_two_price{
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
    color:var(--bg-color-e74);
}
.yoga_col{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.yoga_camp_img{
    position: relative;
    width: 100%;
}

/* Summer Camp India */

/* .summer_camp_p p{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1.5px;
    line-height: 25px;
} */
.summer_camp_title{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 35px;
}

.summer_camp_span span{
  /*   font-style: normal; */
  font-family:'Poppins';
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 35px;
}

.summer_camp_link{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 35px;
}