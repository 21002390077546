.slide-carl {
    position: relative;
    width: 100% !important;
    height: 90vh !important;
    object-fit: cover !important;
    object-position: inherit;
    margin-top: -10px;
}

.slide-cap {
    position: relative;
    display: flex;
    justify-content: left;
    font-size: 32px;
    color: var(--text-color-fff);
    font-weight: 800;
    line-height: 50px;
    margin-bottom: 20px;
}

.slide-cap1 {
    position: relative;
    display: flex;
    justify-content: left;
    font-size: 18px;
    color: var(--text-color-fff);
    font-weight: 500;
    line-height: 50px;
}

.slide-cap-links {
    position: relative;
    display: flex;
    justify-content: left;
    font-size: 36px;
    color: var(--text-color-fff);
    font-weight: 900;
    margin-bottom: 30px;
}

.carousel-control-prev-icon {
    padding: 30px 20px 30px !important;
    background-color: #111111 !important;
}

.carousel-control-next-icon {
    padding: 30px 20px 30px !important;
    background-color: #111111 !important;
}

.slide-link {
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    color: #000;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    text-decoration: none;

}

.slide-link1 {
    font-size: 14px;
    margin-left: 10px !important;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    color: #000;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    text-decoration: none;
}

.slide-link:hover,
.slide-link1:hover {
    text-decoration: none;
    list-style-type: none;
}

@media only screen and (min-width: 340px) and (max-width: 750px) {

    .slide-carl {
        position: relative;
        width: 100% !important;
        height: 60vh !important;
    }

    .slide-cap {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 22px;
        color: var(--text-color-fff);
        font-weight: 900;
        line-height: 50px;
        letter-spacing: 1px;
    }

    .slide-cap1 {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 16px;
        color: var(--text-color-fff);
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 1px;
    }

    .slide-link {
        font-size: 14px !important;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 0px;
        font-weight: 600;
    }

    .slide-link1 {
        font-size: 14px !important;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 0px;
        font-weight: 600;
    }
}

@media only screen and (min-width: 755px) and (max-width: 1030px) {

    .slide-carl {
        position: relative;
        width: 100% !important;
        height: 40vh !important;
    }

    .slide-cap {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 28px;
        color: var(--text-color-fff);
        font-weight: 900;
        line-height: 50px;
        letter-spacing: 1px;
    }

    .slide-cap1 {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 22px;
        color: var(--text-color-fff);
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 1px;
    }

    .slide-link {
        font-size: 18px !important;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 1px;
        font-weight: 600;
    }

    .slide-link1 {
        font-size: 18px !important;
        padding: 10px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 1px;
        font-weight: 600;
    }
}