.navbar{
    position: relative;
    background-color: rgb(245, 245, 245) !important;
    transition: all 150ms linear;
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
}
.logo{
    position: relative;
    display: block;
    width: 200px !important;
    height: 10vh !important;
}
.nav{
    position: relative;
    margin-left: 60px;
    /* padding: 10px 0px 10px !important; */
}
.nav-link{
   position: relative;
   padding: 20px 15px 20px !important;
   font-size: 14px !important;
   font-weight: 700 !important;
   text-transform: uppercase;
   color: var(--bg-color-e74) !important;
   text-transform: uppercase;
}
.nav-link-buy{
    padding: 10px 10px 10px;
    background-color: var(--text-color-fff);
   font-size: 15px;
   font-weight: 800;
   color: var(--bg-color-e74) !important;
   text-align: center;
   text-decoration: none;
   border: 1px solid rgb(31, 226, 14);
   box-shadow: 0px 4px 4px rgb(228, 202, 240);
   border-radius: 5px;
}
.nav-link-buy:hover{
    background-color: rgb(246, 243, 243);
    border: 1px solid rgb(199, 134, 236);
}
.nav .dropdown-menu{
    position: relative;
    margin-top: 0px !important;
    color: var(--bg-color-e74) !important;
    background-color: rgb(245, 245, 245) !important;
}
.nav .dropdown-item{
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: var(--bg-color-e74) !important;
    text-transform: uppercase;
}
.nav .dropdown-item:hover{
   background-color: var(--text-gray-color-447);
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}


@media (max-width: 890px) {
    .nav{
        position: relative;
        margin-left: 0px;
        /* padding: 10px 0px 10px !important; */
    }
}

@media (min-width: 1090px) and (max-width: 1298px){
    .nav{
        position: relative;
        margin-left: 20px;
        /* padding: 10px 0px 10px !important; */
    }
    .nav-link{
        position: relative;
        padding:20px 10px 20px !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: var(--bg-color-e74) !important;
     }
}

@media (min-width: 990px) and (max-width: 1088px){
    .nav{
        position: relative;
        margin-left: 20px;
        /* padding: 10px 0px 10px !important; */
    }
    .nav-link{
        position: relative;
        padding:20px 5px 20px !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: var(--bg-color-e74) !important;
     }
  
}

@media (min-width: 1300px) and (max-width: 1800px){
    .nav{
        position: relative;
        margin-left: 60px;
        /* padding: 10px 0px 10px !important; */
    }
    .nav-link{
        position: relative;
        /* padding: 20px 25px 20px !important; */
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: var(--bg-color-e74) !important;
     }
}