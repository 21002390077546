@import url(https://fonts.googleapis.com/css?family=Open Sans);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
  font-family: "Open Sans Sans-Serif" !important;
  font-style: normal;
  font-weight: 300;
}

p {
  font-family: "Open Sans Sans-Serif" !important;
  letter-spacing: 1px;
  line-height: 1.8;
  font-size: 14px;
}

.row {
 /*  --bs-gutter-x: 0; */
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x)*0.5);
  padding-left: calc(var(--bs-gutter-x)*0.5);
}

.col_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --bg-color-846: #8463F4;
  --bg-color-e74: #4a82cc;
  --text-color-cc8: hsl(194, 94%, 50%);
  --text-color-fff: #FFFFFF;
  --text-purple-color-952: #952DBA;
  --text-gold-color-d4a: #6cce63;
  --text-black-color-000: #161129;
  --text-color-404: #404026;
  --text-orange-color-455: hsl(41, 94%, 50%);
  --text-gray-color-447: hsl(180, 100%, 99%);
  --text-color-0e3: #0e3100;
  --scrollbarBG: #101314;
  --thumbBG: #90A4AE;
}

/* width */
/* ::-webkit-scrollbar {
  width: 11px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: var(--scrollbarBG); */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */
.navbar{
    position: relative;
    background-color: rgb(245, 245, 245) !important;
    transition: all 150ms linear;
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
}
.logo{
    position: relative;
    display: block;
    width: 200px !important;
    height: 10vh !important;
}
.nav{
    position: relative;
    margin-left: 60px;
    /* padding: 10px 0px 10px !important; */
}
.nav-link{
   position: relative;
   padding: 20px 15px 20px !important;
   font-size: 14px !important;
   font-weight: 700 !important;
   text-transform: uppercase;
   color: var(--bg-color-e74) !important;
   text-transform: uppercase;
}
.nav-link-buy{
    padding: 10px 10px 10px;
    background-color: var(--text-color-fff);
   font-size: 15px;
   font-weight: 800;
   color: var(--bg-color-e74) !important;
   text-align: center;
   text-decoration: none;
   border: 1px solid rgb(31, 226, 14);
   box-shadow: 0px 4px 4px rgb(228, 202, 240);
   border-radius: 5px;
}
.nav-link-buy:hover{
    background-color: rgb(246, 243, 243);
    border: 1px solid rgb(199, 134, 236);
}
.nav .dropdown-menu{
    position: relative;
    margin-top: 0px !important;
    color: var(--bg-color-e74) !important;
    background-color: rgb(245, 245, 245) !important;
}
.nav .dropdown-item{
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: var(--bg-color-e74) !important;
    text-transform: uppercase;
}
.nav .dropdown-item:hover{
   background-color: var(--text-gray-color-447);
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}


@media (max-width: 890px) {
    .nav{
        position: relative;
        margin-left: 0px;
        /* padding: 10px 0px 10px !important; */
    }
}

@media (min-width: 1090px) and (max-width: 1298px){
    .nav{
        position: relative;
        margin-left: 20px;
        /* padding: 10px 0px 10px !important; */
    }
    .nav-link{
        position: relative;
        padding:20px 10px 20px !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: var(--bg-color-e74) !important;
     }
}

@media (min-width: 990px) and (max-width: 1088px){
    .nav{
        position: relative;
        margin-left: 20px;
        /* padding: 10px 0px 10px !important; */
    }
    .nav-link{
        position: relative;
        padding:20px 5px 20px !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: var(--bg-color-e74) !important;
     }
  
}

@media (min-width: 1300px) and (max-width: 1800px){
    .nav{
        position: relative;
        margin-left: 60px;
        /* padding: 10px 0px 10px !important; */
    }
    .nav-link{
        position: relative;
        /* padding: 20px 25px 20px !important; */
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: var(--bg-color-e74) !important;
     }
}
.slide-carl {
    position: relative;
    width: 100% !important;
    height: 90vh !important;
    object-fit: cover !important;
    object-position: inherit;
    margin-top: -10px;
}

.slide-cap {
    position: relative;
    display: flex;
    justify-content: left;
    font-size: 32px;
    color: var(--text-color-fff);
    font-weight: 800;
    line-height: 50px;
    margin-bottom: 20px;
}

.slide-cap1 {
    position: relative;
    display: flex;
    justify-content: left;
    font-size: 18px;
    color: var(--text-color-fff);
    font-weight: 500;
    line-height: 50px;
}

.slide-cap-links {
    position: relative;
    display: flex;
    justify-content: left;
    font-size: 36px;
    color: var(--text-color-fff);
    font-weight: 900;
    margin-bottom: 30px;
}

.carousel-control-prev-icon {
    padding: 30px 20px 30px !important;
    background-color: #111111 !important;
}

.carousel-control-next-icon {
    padding: 30px 20px 30px !important;
    background-color: #111111 !important;
}

.slide-link {
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    color: #000;
    border: none;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    text-decoration: none;

}

.slide-link1 {
    font-size: 14px;
    margin-left: 10px !important;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    color: #000;
    border: none;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    text-decoration: none;
}

.slide-link:hover,
.slide-link1:hover {
    text-decoration: none;
    list-style-type: none;
}

@media only screen and (min-width: 340px) and (max-width: 750px) {

    .slide-carl {
        position: relative;
        width: 100% !important;
        height: 60vh !important;
    }

    .slide-cap {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 22px;
        color: var(--text-color-fff);
        font-weight: 900;
        line-height: 50px;
        letter-spacing: 1px;
    }

    .slide-cap1 {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 16px;
        color: var(--text-color-fff);
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 1px;
    }

    .slide-link {
        font-size: 14px !important;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 0px;
        font-weight: 600;
    }

    .slide-link1 {
        font-size: 14px !important;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 0px;
        font-weight: 600;
    }
}

@media only screen and (min-width: 755px) and (max-width: 1030px) {

    .slide-carl {
        position: relative;
        width: 100% !important;
        height: 40vh !important;
    }

    .slide-cap {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 28px;
        color: var(--text-color-fff);
        font-weight: 900;
        line-height: 50px;
        letter-spacing: 1px;
    }

    .slide-cap1 {
        position: relative;
        display: flex;
        justify-content: left;
        font-size: 22px;
        color: var(--text-color-fff);
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 1px;
    }

    .slide-link {
        font-size: 18px !important;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 1px;
        font-weight: 600;
    }

    .slide-link1 {
        font-size: 18px !important;
        padding: 10px 20px;
        border-radius: 30px;
        background-color: #fff;
        letter-spacing: 1px;
        font-weight: 600;
    }
}
.progress-container{
    position: relative;
    padding: 30px 0px 30px;
 
 }
 .progress-title{
     position: relative;
    /*  font-style: normal; */
     font-weight: 600;
     font-size: 20px;
     line-height: 28px;
     color:var(--text-black-color-000);
 }
 
 .progress-title-one{
     font-style: normal;
     font-weight: 900;
     font-size: 22px;
     line-height: 22px;
     color:var(--bg-color-e74);
     text-align: center;
 }
 
 .progress-time{
    /*  font-style: normal; */
     font-weight: normal;
     font-size: 14px;
     font-weight: 600;
     line-height: 20px;
     color:var(--bg-color-e74);
     text-align: center;
 }
 .prog-logo{
     position: relative;
     width: 100%;
 }
 
 @media (max-width: 500px) {
    .progress-title-one{
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 22px;
        color:var(--bg-color-e74);
        text-align: center;
    }
     .progress-time{
        /*  font-style: normal; */
        font-size: 18px; 
        font-weight: 500;
        line-height: 20px;
        color:var(--bg-color-e74);
        text-align: center;
    }
 }
.homeabout-main {
    position: relative;
    padding: 10px 0px 0px;
}

.homeabout-title {
    position: relative;
    padding: 30px 0px 30px;
    /*  font-style: normal; */
    font-weight: 700;
    font-size: 30px;
    line-height: 22px;
    color: var(--bg-color-e74);
    text-align: center;
}

.homeabout-col p {
    /*  font-style: normal; */
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 22px;
    color: var(--text-black-color-000);
    text-align: justify;
}

.homeabout-image {
    position: relative;
    /* display: block; */
    width: 100%;
}

.about-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 42px;
    padding: 0 20px;
    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 10px;
    outline: none;
    background-color: var(--text-gold-color-d4a);

}

/* .cbh{
    font-size: 16px;
 
} */

@media (max-width: 600px) {

    .homeabout-main {
        position: relative;
        padding: 0px 0px 0px;
    }

    .homeabout-title {
        position: relative;
        padding: 30px 0px 30px;
        /*   font-style: normal; */
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
        color: var(--bg-color-e74);
        text-align: center;
    }

    .homeabout-col p {
        font-weight: 400;
        /* font-size: 14px;
        line-height: 30px;
       letter-spacing: 1px;
       line-height: 1.8;
       font-size: 14px; */
        color: var(--text-black-color-000);
        text-align: justify;
        padding-left: 5px;
    }

    /* .res-about{
    width: 800px;
   } */

    .homeabout-image {
        position: relative;
        /* display: block; */
        width: 100%;
        margin-bottom: 40px;
    }

}
.homeservice-image{
    position: relative;
    display: block;
    width: 20% !important;
    height: 15% !important;
    margin-top: 10px;
    
}
.card{
    min-width: 335px !important;
}
.card-image{
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    background-color: 	#fff !important;
    border-radius: 10px !important;
    transition: all 0.3s ease-out;
    box-shadow: 0 0 40px rgba(207, 206, 206, 0.938);
    box-sizing: border-box;
    width: 90%;
}
.card-image:hover{
    cursor: pointer;
    border-color:  var(--text-gold-color-d4a);
    border-radius: 20px !important;
    box-shadow: 0 0 30px rgba(97, 107, 98, 0.938);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    /*backface-visibility: hidden;
    transform: scale(0.9,0.9);*/
    opacity: 1;
    
}
.homeservice-title{
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color:var(--text-color-000);
    text-align: center;
}
.homeservice-link{
    font-size: 16px !important;
    font-weight: 600 !important;
   /*  line-height: 50px; */
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: var(--text-gold-color-d4a) !important;
    border-radius: 10px !important;
    outline: none;
    background-color: var(--text-gold-color-d4a) !important;
}

.home-services-main-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}
.homeservice-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--text-color-000) !important;
}

/* Home Explore */

.home-explore-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40vh !important;
    margin-top:50px ;
    /* background-color: #ED5001; */
    color: #fff;
    background-image: url(/static/media/healthy-bg.eed5af97.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.home-explore-title{
  font-size: 30px;
  font-weight: 600;
}
.home-explore-text{
  font-size: 14px;
}

  .home-contact-btn{
    font-size: 16px !important;
    font-weight: 600 !important;
   /*  line-height: 50px; */
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: var(--text-gold-color-d4a) !important;
    border-radius: 10px !important;
    outline: none;
    background-color: var(--text-gold-color-d4a) !important;
}

.home-product-container{
    position: relative;
    padding: 30px 0px 30px;
    background-color: #dee2e6;
}

.home-product-main-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}

.home-product-image:hover{
    cursor: pointer;
    border-color:  var(--text-gold-color-d4a);
    box-shadow: 0 0 40px rgba(147, 168, 150, 0.938);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    z-index: 1;
}

.home-product-title{
    position: relative;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color:var(--text-black-color-000);
}

.home-product-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-black-color-000) !important;
}
.footer-container{
    position: relative;
    width: 100%;
    background-color: #184631;
    color: white;
}
.footer-container-one{
    position: relative;
    padding: 30px 10px 30px;
}
.footer-quick-link{
    text-align: left;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    font-size: 25px;
}
.footer-link{
    text-align: left;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #ccc;
    list-style-type: none;
}

.footer-link-a a{
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #ccc;
    text-decoration: none;
}
.footer-icon-div a{
    position: relative;
    /* display: -webkit-inline-flex; */
    padding: 5px 5px 5px;
    text-decoration: none;
}

.footer-icon{
    width: 10%;
}
.footer-image{
  width: 100%;
  background-color: #fff;
}
.footer_text{
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #ccc;
}

@media (max-width: 500px) {
    .footer-container-one{
        position: relative;
        padding: 30px 30px 30px;
    }
    .footer-image{
        width: 100%;
        background-color: #fff;
      }
      .footer-quick-link{
        text-align: left;
        font-weight: 700;
        font-style: normal;
        color: #fff;
        font-size: 25px;
    }
    .footer-icon{
        width: 20%;
    }
    .footer-col-3{
        margin-left: -30px;
    }
}

.home_ourlocation-title{
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color:var(--bg-color-e74);
    text-align: center;
}
.home-location-title{
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color:var(--text-black-color-000);
}
.home-location-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-black-color-000) !important;
}
.home-location-col{
    background-color: rgb(238, 238, 238) !important;
    cursor: pointer;
    /* box-shadow: 0 0 40px rgba(207, 206, 206, 0.938); */
    box-sizing: border-box;
}
/* Home Contact */

.home-contact-container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;
  background-image: url(/static/media/map-bg.8437ca62.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.home-contact-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--text-color-cc8);
}

.home-contact-title-one {
  position: relative;
  padding: 20px 0px 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 42px;
  color: var(--bg-color-e74);
  text-align: start;
}

.home-contact-title-one {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.home-contact-list li {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0;
  margin-top: 20px;
  list-style: none;
  margin-bottom: 30px;
}

.home-contact-list a {
  letter-spacing: 1.5px;
}

.home-contact-img {
  display: inline;
  width: 40px;
  margin-right: 10px;
}

.home-contact-form-col {
  position: relative;
  background: #ffffff;
  overflow: hidden;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 43px 0px rgb(131 131 131 / 23%);
}

.contact-form {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.address {
  margin-left: 40px;
  font-size: 13px;
}

/* Contact One */

.add_card_title {
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: var(--bg-color-e74) !important;
  text-transform: uppercase !important;
}

.add_card_title_one {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
}

.add_card_text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 1px !important;
}
.about-container {
    position: relative;
    padding: 30px 120px 30px;
    text-align: justify;
   /*  font-size: 16px; */
}

.about-image {
    display: block;
    width: 40% !important;
}

.about-title {
    position: relative;
    padding: 30px 0px 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color: var(--bg-color-e74);
    /* text-align: center; */
}

/* About One */

.about-one-container-one {
    position: relative;
    margin-bottom: 50px;
    background-color: transparent;
    /* background-image: linear-gradient(250deg, #fe4c2e 0%, #f12184 94%); */
    background: linear-gradient(to bottom, #e0f8ff 0, #86d0ad 100%);
    opacity: 0.81;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    padding: 60px 40px 60px 40px !important;
}

.about-one-container {
    position: relative;
    margin-bottom: 50px;
}

.about-one-card {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    padding: 60px 40px 60px 40px !important;
    background-color: #ffffff !important;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #d8d8d8;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
    justify-content: cenrter !important;
}

.about-one-card-head {
    font-size: 18px;
    font-weight: 900;
    color: var(--bg-color-e74);
    margin-bottom: 30px !important;
}

.about-one-col {
    position: relative;
    text-align: center;
}

/* About Two */
.about-two-title {
    position: relative;
    padding: 20px 0px 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color: var(--bg-color-e74);
}

.about-two-ul {
    list-style-type: none;
    padding-bottom: 20px;
}

.about-two-ul li {
    margin: 0px 0px 5px;
    position: relative;
    /* padding-left: 20px; */
    font-size: 16px; 
    line-height: 1.63;
     font-weight: 600; 
      font-style: 18px;
      font-family:'Poppins';
   /*  font-stretch: normal;     */
    color: #333333;
    text-align: justify;
}

.about-two-ul li:before {
    content: "\21E8" !important;
    font-family: FontAwesome;
    position: relative;
    left: 0px;
    top: 0;
    margin-right: 5px;
    font-size: 18px;
}

/* About Stores */

/* About Store */


.about-store-title {
    position: relative;
    /* padding: 30px 0px 30px; */
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 22px;
    color: var(--bg-color-e74);
    /* text-align: center; */
}

.about-store-title-one {
    position: relative;
    /* padding: 30px 0px 30px; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: var(--bg-color-e74);
    /* text-align: center; */
}

.about-store-text p {
  /*   font-style: normal; */
    font-weight: 400;
   /*  font-size: 14px;
    line-height: 22px; */
    color: var(--text-black-color-000);
    text-align: justify;
}

.about-store-text-one {
   /*  font-style: normal; */
    font-weight: 400;
 /*    font-size: 14px;
    line-height: 22px; */
    color: var(--text-color-fff);
    text-align: justify;
}

.about_store_col {
    display: flex;
    justify-content: center;
    align-items: center;
   /*  font-style: normal; */
    font-weight: 400;
   /*  font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.5px; */
}

.about_store_col_title {
 /*    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 22px; */
    color: var(--bg-color-e74) !important;
    font-weight: 700;
    font-size: 18px;
    font-family:'Poppins';
    text-align: center;
}

/* Medical Team */

.medical-team-col p {
   /*  font-style: normal; */
    font-weight: 400;
   /*  font-size: 14px;
    line-height: 22px; */
    color: var(--text-black-color-000);
    text-align: justify;
}

.medical-team-title {
    position: relative;
    /* padding: 30px 0px 30px; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: var(--bg-color-e74);
    text-align: left !important;
}

.medical-team-text {
/*     font-style: normal; */
    font-weight: 600;
   /*  font-size: 16px;
    line-height: 22px; */
    color: var(--text-black-color-000);
    text-align: justify;
}

.medical-team-des {
   /*  font-style: normal; */
    font-weight: 400;
  /*   font-size: 14px;
    line-height: 22px; */
    color: var(--text-black-color-000);
    text-align: justify;
}

.medical-team-link {
  /*   font-size: 16px; */
    font-weight: 600;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 42px;
    padding: 0 30px;
    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 10px;
    outline: none;
    background-color: var(--text-gold-color-d4a);
}

.accomodation {
    color: var(--bg-color-e74) !important;
    font-weight: 700;
    font-size: 18px;
    
}

.accp {

    /* font-style: normal; */
    font-weight: 400;
  /*   font-size: 14px;
    line-height: 22px; */
    color: var(--text-black-color-000);
    text-align: justify;
}

.hf {
    color: blue;
}
/*ortho part*/

.Kalpataru-ortho {
  background: #ffffff;
  padding: 50px 20px 80px 20px;
  margin:20px 0 0 0;
  box-shadow: 0 0 6px 0 #c5c5c5;
}
.Kalpataru-ortho h4 {
  color: #0191ce;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  letter-spacing: 1px;
  padding-bottom:15px;
  padding-top:15px;
}

section.Kalpataru-content1 {
  margin: 1;
  padding-bottom: 50px;
}

.serviceImg h6 {
  padding: 25px 0 30px 0;
}

.abtImg img{
  width:100%;
  padding-top: 70px;
}
.ortho-card img {
  width: 100%;
  height: auto;
}
.ortho-content {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0091d0;
  width: 100%;
  text-align: center;
  padding: 10px 0px 0 0;
  opacity: 0.9;

}
.app-heading{
  color:#0191ce;
  font-weight:700;
  font-size: 18px;
  

}


.ortho-card {
  background: #000;
  text-align: center;
  position: relative;
  transition: .4s ease;
}
.ortho-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.ortho-content p {
  color: #fff;
 font-size: 15px; 
}
.ortho-card .title {
  font-weight: 700;
  color: #fff;
  line-height: 24px;
  margin: -12px 0;
  position: absolute;
  bottom: 50%;
  text-align: center;
  width: 100%;
  margin-left: -20px;
}
.ortho-card .ortho-card-title {
  padding: 30px 10px 30px 20px;
  position: absolute;
  opacity: 0;
  border: 1px solid #fff;
}

    
/* .kalpataru-form {
  background: rgb(217,209,119);
  background: linear-gradient(90deg, rgba(217,209,119,1) 0%, rgba(217,252,250,1) 0%, rgba(233,226,146,1) 0%, rgba(202,250,197,1) 0%, rgba(240,221,186,1) 100%, rgba(238,199,235,1) 100%, rgba(198,225,204,1) 100%, rgba(255,255,255,1) 100%, rgba(217,239,212,1) 100%, rgba(174,235,126,1) 100%, rgba(212,183,124,1) 100%, rgba(233,207,129,1) 100%, rgba(236,249,239,1) 100%, rgba(217,249,249,1) 100%, rgba(139,184,215,1) 100%, rgba(141,128,238,1) 100%, rgba(223,202,185,1) 100%, rgba(222,236,250,1) 100%, rgba(206,209,200,1) 100%);
      padding: 28px 20px!important;
      height: 600px;
  }
  
  
 

.kalpataru-form .form-control {
  display: block;
  width: 100%;
  height: calc(2.0rem + 10px);
  padding: .375rem .75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  width: 100%;
  font-size: 13px;
  margin-bottom:10px;
}*/
.ortho-left-side{
  background-color: #0191ce;
  padding: 20px 25px;
  border-radius: 5px;
}


.ortho-left-side ul li{
  color: #212529;
  overflow: hidden;
  line-height: 45px;
  border-bottom: 1px solid #d2d2d2;
}

.main-btn {
  display: inline-block;
  font-weight: 500;
  margin-left: 20px;
  text-align: center;
  border: 1px solid #ffc600;
  padding: 0 25px;
  line-height: 40px;
  color: #07294d;
  background-color: #ffc600;
}


.ortho-card-secondpart{
  background-color: #0091d0;
  padding: 25px 30px 30px;
  border-radius: 5px;
}
.ortho-card-secondpart .ortho-image-part{
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.ortho-card-secondpart .ortho-image-part .image::before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(7, 41, 77, 0.8);
}


.ortho-card-secondpart .ortho-image-part .cont{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 30px;
}
.ortho-card-secondpart .ortho-image-part .cont h4{
  font-size: 18px;
  color: #fff;
  font-weight: 600;
 
}
.treatment-ortho-ul{
  list-style-type: none;
  padding-bottom:0px;
}
.treatment-ortho-ul li{
 /*  margin: 0px 0px 5px; */
  position: relative;
  /* padding-left: 20px; */
 /*  font-size: 15px; */
  margin-left: -20px;
  /* line-height: 1.63; */
  font-weight: 400;
 /*  font-style: normal;
  font-stretch: normal; */
  color: var(--text-black-color-000);
  text-align: justify;
  font-family:'Poppins';
  letter-spacing: 1px;
  line-height: 1.8;
  font-size: 13px; 

}

.treatment-ortho-ul li:before{
  content: "\21E8" !important;
  font-family: FontAwesome;
  position: relative;
  left: 0px;
  top: 0;
  margin-right: 5px;
  font-size: 18px;
}

/* .para-text{
  font-size: 15px;
} */
.neuro-box {
	margin-bottom: 40px;
}

.neuro-icon {
	margin: 0 20px 20px 0;
	float: left;
}	

.neuro-desc h5 {
	margin-bottom: 10px;
}
.img-responsive{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}
.box-shadow{
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    transition: all 0.3s ease-out;

} 

.box-shadow:hover{
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: scale(1.05,1.05);
            transform: scale(1.05,1.05);
    opacity: 1;

}
.card-img-top{
  height: 200px;
}
.card-title{
   text-align: center;
   font-weight: 700;
    color: #2D4988;
    font-family: "Open Sans", sans-serif;
    font-size: 23px;
}
.card-title h4{
  font-weight: 500;
}
.obesity img{
    transition: all 1.1s ease;
}
.obesity:hover img{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.obesity{
overflow: hidden;

}
/* cards*/
.womenchild .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 1px solid green;
 box-shadow: 0px 5px 5px -4px rgb(0 0 0 / 25%);
  border-radius: 8px;
   transition: all 0.3s ease-out;
  z-index: 1;
}
.womenchild .icon-box:hover {
   box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
     -webkit-backface-visibility: hidden;
             backface-visibility: hidden;
    -webkit-transform: scale(1.10,1.10);
            transform: scale(1.10,1.10);
    opacity: 1;
}

.womenchild .icon-box::before {
  content: '';
  position: absolute;
  background: #cbe0fb;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.womenchild .icon-box:hover::before {
   background:#2D4988;
  top: 0;
  border-radius: 0px;

}

.womenchild .icon {
  margin-bottom: 15px;
}

.womenchild .icon i {
  font-size: 48px;
  line-height: 1;
  color: #106eea;
  transition: all 0.3s ease-in-out;
}

.womenchild .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.womenchild .title a {
  color: #111;
  text-decoration: none;
}

.womenchild .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.womenchild .icon-box:hover .title a, .womenchild .icon-box:hover .description {
  color: #fff;
}

.womenchild .icon-box:hover .icon i {
  color: #fff;
}

/* Women3*/


.beforechildbirth {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}
.beforechildbirth .image-wrapper {
  display: block;
  width: 50%;
}
.beforechildbirth .image-wrapper img {
  width: 100%;
}
@media screen and (max-width: 767.98px) {
  .beforechildbirth .image-wrapper {
    width: 100%;
  }
}
.beforechildbirth .content-women {
  display: block;
  width: 50%;
}
@media screen and (max-width: 767.98px) {
  .beforechildbirth .content-women {
    width: 100%;
  }
}
.beforechildbirth .content-women .wrapper {
  display: block;
  margin: 1.25rem 0;
  padding: 0 2.5rem;
  position: relative;
}
.beforechildbirth .content-women .wrapper ol li {
  display: inline-block;
  margin: 1.25rem 0;
  position: relative;
  display: block;
  padding: 0.3125rem 0;
}
.beforechildbirth .content-women .wrapper ol li .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.3125rem 0;
}
.beforechildbirth .content-women .wrapper ol li .name span {
  font-size: 1.125rem;
  color: #0a130e;
}
.beforechildbirth .content-women .wrapper ol li .name h6 {
  display: inline-block;
  font-size: 1.125rem;
  color: #0a0a13;
  margin: 0.3125rem 0;
  text-transform: capitalize;
}
.beforechildbirth .content-women .wrapper ol li .progress {
  height: 0.625rem;
  border-radius: 1.25rem;
}
.beforechildbirth .content-women .wrapper ol li .progress .progress-bar {
  background: #0a8f32;
}
.beforechildbirth .content-women .inner {
  padding: 0.625rem 0;
  position: relative;
}
.beforechildbirth .content-women .inner span {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  color: var(--bg-color-e74);
  padding-left: 0.9375rem;
  margin: 0.625rem 0;
}
.beforechildbirth .content-women .inner span::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 0.1875rem;
  background: #199137;
}
.beforechildbirth .content-women .inner h2 {
  font-size: 1.185rem;
  color: #0a0a13;
  display: block;
  margin: 0.625rem 0;
  position: relative;
  text-transform: capitalize;
}
.beforechildbirth .content-women .inner p {
  font-size: 0.9375rem;
  color: #666666;
  display: block;
  margin: 0.625rem 0;
}
.beforechildbirth .content-women .inner ol {
  width: 100%;
  flex-wrap: wrap;
}
.beforechildbirth .content-women .inner ol li {
  width: 33.3%;
  padding: 0.9375rem 0.625rem;
  position: relative;
  display: flex;
  margin: 0.625rem 0;
  transition: all ease 0.5s;
}
@media screen and (max-width: 1044px) {
  .beforechildbirth .content-women .inner ol li {
    width: 100% !important;
    margin: 0px;
    padding: 5px;
  }
}
.beforechildbirth .content-women .inner ol li:hover {
  background: rgb(0,36,9);
background: linear-gradient(90deg, rgba(0,36,9,1) 0%, rgba(76,168,59,1) 100%, rgba(0,212,255,1) 100%);
  border-radius: 0.3125rem;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.03), 0 1px 4px rgba(0, 0, 0, 0.04);
}
.beforechildbirth .content-women .inner ol li:hover div {
  color: #fff;
}
.beforechildbirth .content-women .inner ol li:hover h4 {
  color: #fff;
}
@media screen and (max-width: 575.98px) {
  .beforechildbirth .content-women .inner ol li {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .beforechildbirth .content-women .inner ol li {
    width: 50%;
  }
}
.beforechildbirth .content-women .inner ol li div {
  font-size: 1.25rem;
  color: #30057b;
  margin-right: 0.625rem;
  font-weight: bold;
}
.beforechildbirth .content-women .inner ol li h4 {
  font-size: 1rem;
  color: #0a0a13;
  font-weight: 600;
  line-height: 1.5625rem;
  letter-spacing: 0.3px;
}
.beautycare img {
  width: 475px;
  height: 300px;
  display: block;
box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%);
}
.beautyheading{
  color: var(--bg-color-e74);
  font-weight: 600;
  font-size: 30px;
}
.result{
  font-size:50px;
  color:green;
  margin-right:15px;
  }
  /*   p {
      font-size: 15px;
    } */
  .media p {
      font-size: 18px;
      padding-top: 10px;
        color: green;
  }
  .media {
    
    display: flex;
    
}

   .diagnosis-banner1{
    background:#BCCCFE;
       background-size: cover;
        
   }
   .diagnosis-banner2{
    background:#7dc580;
       background-size: cover;
     
       
   }
   .diagnosis-banner3{
    background:#3DA7FE;
       background-size: cover;
        
   }
   
   .ds-cont{
   padding:110px 0px;
   }
   .ds-cont h2 {
       font-size: 33px;
       color: #FFF;
       padding-bottom: 30px;
   }
   .ds-cont p {
       font-size: 16px;
       color: #FFF;
       line-height: 30px;
   }
   .ds-cont1{
   padding:110px 0px;
   }
   .ds-cont1 h2 {
       font-size: 33px;
       color:black;
       padding-bottom: 30px;
   }
   .ds-cont1 p {
       font-size: 16px;
       color:black;
       line-height: 30px;
   }
   .challenges1{
      font-weight: 700;
       color: black;
     font-family: "Open Sans", sans-serif;
   }
   
   .challenges span{
   
    color: #fb5363;
   }
   
   .Diagnosisimg{
    max-width: 100%;
       height:450px;

   }
   
   @media only screen and (max-width: 767px){
    
     .ds-cont p {
       font-size: 17px !important;
       color: #FFF;
       line-height: 1.5;
   }
    
   }
   
   
   
  
/* heading*/
.section_heading {
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
}
h1 {
    font-size: 32px !important;
    font-weight: 900 !important;
    margin: 0.67em 0;
}
.section_heading h1 span, .section_heading h2 span {
     color: var(--bg-color-e74);
}
.separator {
    margin: 5px auto 0px;
    width: 120px;
    position: relative;
}
.separator ul {
    padding: 0px;
    margin: 0px;
}
ul, li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    font-family:'Poppins';
    letter-spacing: 1px;
    line-height: 1.8;
    font-size: 14px; 
}
/* ul, li {
    padding: 0px;
    margin: 0px;
} */

.separator ul::before {
    content: '';
    width: 40px;
    height: 1px;
    background: #c9c9c9;
    left: 0;
    top: 61%;
    position: absolute;
}
.separator ul li {
    display: inline-block;
    width: 3px;
    height: 7px;
    margin: 0px 2px;
    padding: 0px;
}
.separator ul li {
    background: #2E8B57
}



.separator ul::after {
    content: '';
    width: 40px;
    height: 1px;
    background: #c9c9c9;
    right: 0;
    top: 61%;
    position: absolute;
}

/* About Hospital One */

.single-feature {
    position: relative;
    padding: 30px;
    transition: all .3s ease 0s;
 /*    box-shadow: 0 0 50px rgb(153 153 153 / 20%); */
}

.single-feature .feature-head {
    margin-bottom: 15px;
    margin-left: -60px !important;
    
    
}
/* .single-feature .feature_head {
    position: absolute;
} */
.single-feature .feature-head h4 {
    padding: 11px 0 0 60px;
    font-size: 18px;
    font-weight: 700;
    color: var(--bg-color-e74) !important;
}

.single-feature .feature-btn .f-btn {
    font-weight: 500;
    color: #222;
    transition: all .3s ease 0s;
}
.f-btn {
    text-decoration: none;
    transition: all .3s ease-in-out;
    text-decoration: none;
    background-color: transparent;
}
.f-btn:hover{
    color: #007bff !important;
}
.single-feature:hover {
    cursor: pointer;
    box-shadow: 0 0 50px rgba(221, 218, 218, 0.2);
}
.bread_row_bg{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
 /*    margin-top: 90px; */
    height: 70vh;
    /* background-image: url("/src/Utils/Images/latest/Promo4.jpeg"); */
    background-size: cover;
    background-position: center;
}
.breadcrumb-list{
    opacity: none;
    color: #fff !important;
    font-weight: 600;
    text-decoration: none;
}
.breadcrumb-divider{
    position: relative;
    font-size: 20px;
    font-weight: 600 !important;
    color: #fff;
    padding: 10px;
    font-weight: 800;
}

.expo_img{
    position: relative;
    display: block;
    width: 100%;
} 
.expo_one_title{
 /*    font-style: normal; */
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color:var(--bg-color-e74);
}
.expo_col{
/*     font-style: normal; */
    font-weight: 400;
    /* font-size: 14px;
    line-height: 22px; */
    color:var(--text-black-color-000);
    text-align: justify;
}
.expo_one_row_bg{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    /* text-align: center; */
}
.expo_one_btn{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px !important;
    color: var(--text-color-fff) !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    height: 42px !important;
    padding: 0 40px !important;
    cursor: pointer !important;
    white-space: nowrap !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    outline: none !important;
    background-color:var(--text-gold-color-d4a) !important;
}

/* YogaOne Camp */

.yoga-camp_swiper{
    width: 100%;
    height: 100%;
}
.yoga_camp_slide{
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
.yoga_camp_slide img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.yoga_camp_p{
    /* font-style: normal; */
    font-weight: 600;
    font-size: 46px;
    /* line-height: 22px; */
    color:var(--text-black-color-000);
}
.yoga_camp_btn{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px !important;
    color: var(--text-color-fff) !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-decoration: none !important;
    height: 42px !important;
    padding: 0 40px !important;

    cursor: pointer !important;
    white-space: nowrap !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    outline: none !important;
    background-color:var(--text-gold-color-d4a) !important;
}
.yoga_img{
    background-color: #7F3F98;
    opacity: 0.5;
}
.yoga_title{
    text-align: center;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 500;
}
.yoga_two_title{
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 22px;
  color:var(--bg-color-e74);
  text-align: center;
}
.yoga_btn{
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
}
.yoga_camp_two_price{
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
    color:var(--bg-color-e74);
}
.yoga_col{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color:var(--text-black-color-000);
    text-align: justify;
}
.yoga_camp_img{
    position: relative;
    width: 100%;
}

/* Summer Camp India */

/* .summer_camp_p p{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1.5px;
    line-height: 25px;
} */
.summer_camp_title{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 35px;
}

.summer_camp_span span{
  /*   font-style: normal; */
  font-family:'Poppins';
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 35px;
}

.summer_camp_link{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 35px;
}
.register_col_form{
    position: relative;
    background: #ffffff;
    overflow: hidden;
    padding: 40px 60px 60px !important;
    border-radius: 15px;
    box-shadow: 0px 0px 43px 0px rgb(131 131 131 / 23%);
    margin-left: -10px;
  }
  .register_col_form_one{
      position: relative;
      background: #ffffff;
      overflow: hidden;
      padding: 40px 60px 60px !important;
      border-radius: 15px;
      box-shadow: 0px 0px 43px 0px rgb(131 131 131 / 23%);
      margin-left: 10px;
    }
  
  .register_col_form input{
      border-radius: 15px !important;
      line-height: 2;
      font-size: 14px;
      font-family: 'Poppins';
  }
  .register_col_form select{
      border-radius: 15px !important;
  }
  .register_col_form label{
      font-size: 15px;
      font-family: 'Poppins';
      letter-spacing: 0.5px !important;
      font-weight: 500;
  }
  .register_form_col_btn{
      position: relative;
      margin-top: 40px !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
      
  }
  .register_form_btn{
      padding: 10px 15px 10px;
      background-color: var(--bg-color-e74);
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color-fff) !important;
      text-align: center;
      text-decoration: none;
      border: 1px solid var(--text-color-fff);
      box-shadow: 0px 4px 4px rgb(228, 202, 240);
      border-radius: 10px;
      /* margin-top: 20px; */
      margin-left: 0px !important;
      margin-right: 0px !important;
  }
  .register_col_form_one h3{
      font-size: 16px;
      letter-spacing: 0.5px !important;
      font-weight: 500;
      color: var(--text-color-222);
      margin-bottom: 10px;
      text-align: center;
  }
  .register_col_form_one h4{
      font-size: 14px;
      font-family: 'Poppins';
      letter-spacing: 0.5px !important;
      font-weight: 500;
      color: var(--text-color-000);
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      line-height: 1.5;
  }
  
  /* Success Page */
  
  .success_page{
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      /* flex-direction: row; */
      justify-content: center;
      align-items: center;
      text-align: center;
  }
  .lottie_img{
      position: relative;
      width: 20% !important;
  }
  .success_link{
      padding: 10px 20px 10px;
      background-color: var(--bg-color-e74);
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color-fff) !important;
      text-align: center;
      text-decoration: none;
      border: 1px solid var(--text-color-fff);
      box-shadow: 0px 4px 4px rgb(228, 202, 240);
      border-radius: 10px;
      margin-top: 20px !important;
     
  }
  .success_text{
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      color: var(--bg-color-e74);
      
  }
  .hecolor{
    color: var(--bg-color-e74) !important;
  }

    .services-head {
        flex: 1 1 auto;
        padding: 10px 20px;
        background:#1e6544;
        
        
    }
    .services-title{
        color: white;
        font-size: 17px;
        text-align: center;
    }
    .service-card{
    margin-bottom:30px;
    }
    
    .service-card .card ul{
    padding-left:0px;
    margin-left:20px; 
      
    }
    .service-card .card ul li{
        text-align: left;
        font-family:'Poppins';
        letter-spacing: 1px;
        line-height: 1.8;
        font-size: 13px; 
        padding-bottom: 10px;

         
        }
  
    .services-body{
        min-height: 330px;
        margin-top: 15px;
    }
.franchise_title {
    /* font-style: normal; */
    font-family:'Poppins';
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    /* color:var(--bg-color-e74); */
    text-align: center;
}

.franchise_title_one {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* color:var(--bg-color-e74); */
    text-align: center;
}

.franchise_title_two {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--bg-color-e74);
    text-align: center;
}

.franch_row {
    background-image: url(/static/media/franch.84fc69a3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.summer_camp_list {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--text-color-fff);
    background-color: #696969;
    padding: 10px;
    margin-top: 5px;
    width: 50%;
    padding: 20px;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 1px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.summer_camp_list span {
  /*   font-style: normal; */
  font-family:'Poppins';
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--text-color-fff);
}

@media (min-width: 360px) and (max-width: 720px){
    .summer_camp_list {
        width: 100%;
    }
}

/* .img_container{
    background-color:#F0F8FF;
    padding: 50;
}
 */

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height:550px !important;
   
}
.image-gallery-slide .image-gallery-image {
    width: 100% !important;
   object-fit: cover !important;
}


.infertility{
    text-align: center;
    height: 500px;
}
.infertility_img{
    height: 400px;
    width: 900px;
}
.infer_text{
    font-weight:500;
    color: blue;
    font-size: 30px;
  
}

/* Home Facilities */

.homefacilities-container{
    position: relative;
    padding: 30px 0px 30px;
    /* background-color: #dad2d2; */
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center !important;
  font-size: 18px;
  background: linear-gradient( 
    0deg, rgb(10 67 76) 0%, rgb(30 97 108) 48%);


  /* Center slide text vertically */
  display: -webkit-flex;
  display: inline-table;
  justify-content: center;
  align-items: center;
}
.homefacility-slider{
  display:flex;
  flex-direction: column-reverse;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.slide-img {
  display: flexbox;
  width: 60%;
}

.homefacility-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 50vh !important;
  padding: 50px 40px 55px;
  text-align: center;
}

.homefacility-main-title{
  position: relative;
  padding: 20px 0px 20px;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 22px;
  color:var(--bg-color-e74);
  text-align: center;
}

.homefacility-title{
  position: relative;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color:white;
}

.homefacility-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: white;
}

.homefacility-link{
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: var(--text-color-fff) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 42px;
    padding: 0 20px;

    cursor: pointer;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 10px;
    outline: none;
    background-color:var(--text-gold-color-d4a);
}


/*
-----------------------------------------------------------------------------------

    Template Name: Kalpataru
    Version: 1.0

-----------------------------------------------------------------------------------
    
 CSS INDEX
 ===================

*/

/* Swiper */
