/*ortho part*/

.Kalpataru-ortho {
  background: #ffffff;
  padding: 50px 20px 80px 20px;
  margin:20px 0 0 0;
  box-shadow: 0 0 6px 0 #c5c5c5;
}
.Kalpataru-ortho h4 {
  color: #0191ce;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  letter-spacing: 1px;
  padding-bottom:15px;
  padding-top:15px;
}

section.Kalpataru-content1 {
  margin: 1;
  padding-bottom: 50px;
}

.serviceImg h6 {
  padding: 25px 0 30px 0;
}

.abtImg img{
  width:100%;
  padding-top: 70px;
}
.ortho-card img {
  width: 100%;
  height: auto;
}
.ortho-content {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0091d0;
  width: 100%;
  text-align: center;
  padding: 10px 0px 0 0;
  opacity: 0.9;

}
.app-heading{
  color:#0191ce;
  font-weight:700;
  font-size: 18px;
  

}


.ortho-card {
  background: #000;
  text-align: center;
  position: relative;
  transition: .4s ease;
}
.ortho-card:hover {
  transform: translateY(-10px);
}
.ortho-content p {
  color: #fff;
 font-size: 15px; 
}
.ortho-card .title {
  font-weight: 700;
  color: #fff;
  line-height: 24px;
  margin: -12px 0;
  position: absolute;
  bottom: 50%;
  text-align: center;
  width: 100%;
  margin-left: -20px;
}
.ortho-card .ortho-card-title {
  padding: 30px 10px 30px 20px;
  position: absolute;
  opacity: 0;
  border: 1px solid #fff;
}

    
/* .kalpataru-form {
  background: rgb(217,209,119);
  background: linear-gradient(90deg, rgba(217,209,119,1) 0%, rgba(217,252,250,1) 0%, rgba(233,226,146,1) 0%, rgba(202,250,197,1) 0%, rgba(240,221,186,1) 100%, rgba(238,199,235,1) 100%, rgba(198,225,204,1) 100%, rgba(255,255,255,1) 100%, rgba(217,239,212,1) 100%, rgba(174,235,126,1) 100%, rgba(212,183,124,1) 100%, rgba(233,207,129,1) 100%, rgba(236,249,239,1) 100%, rgba(217,249,249,1) 100%, rgba(139,184,215,1) 100%, rgba(141,128,238,1) 100%, rgba(223,202,185,1) 100%, rgba(222,236,250,1) 100%, rgba(206,209,200,1) 100%);
      padding: 28px 20px!important;
      height: 600px;
  }
  
  
 

.kalpataru-form .form-control {
  display: block;
  width: 100%;
  height: calc(2.0rem + 10px);
  padding: .375rem .75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  width: 100%;
  font-size: 13px;
  margin-bottom:10px;
}*/
.ortho-left-side{
  background-color: #0191ce;
  padding: 20px 25px;
  border-radius: 5px;
}


.ortho-left-side ul li{
  color: #212529;
  overflow: hidden;
  line-height: 45px;
  border-bottom: 1px solid #d2d2d2;
}

.main-btn {
  display: inline-block;
  font-weight: 500;
  margin-left: 20px;
  text-align: center;
  border: 1px solid #ffc600;
  padding: 0 25px;
  line-height: 40px;
  color: #07294d;
  background-color: #ffc600;
}


.ortho-card-secondpart{
  background-color: #0091d0;
  padding: 25px 30px 30px;
  border-radius: 5px;
}
.ortho-card-secondpart .ortho-image-part{
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.ortho-card-secondpart .ortho-image-part .image::before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(7, 41, 77, 0.8);
}


.ortho-card-secondpart .ortho-image-part .cont{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 30px;
}
.ortho-card-secondpart .ortho-image-part .cont h4{
  font-size: 18px;
  color: #fff;
  font-weight: 600;
 
}
.treatment-ortho-ul{
  list-style-type: none;
  padding-bottom:0px;
}
.treatment-ortho-ul li{
 /*  margin: 0px 0px 5px; */
  position: relative;
  /* padding-left: 20px; */
 /*  font-size: 15px; */
  margin-left: -20px;
  /* line-height: 1.63; */
  font-weight: 400;
 /*  font-style: normal;
  font-stretch: normal; */
  color: var(--text-black-color-000);
  text-align: justify;
  font-family:'Poppins';
  letter-spacing: 1px;
  line-height: 1.8;
  font-size: 13px; 

}

.treatment-ortho-ul li:before{
  content: "\21E8" !important;
  font-family: FontAwesome;
  position: relative;
  left: 0px;
  top: 0;
  margin-right: 5px;
  font-size: 18px;
}

/* .para-text{
  font-size: 15px;
} */